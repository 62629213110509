<template>
  <div class="home">
    <div class="data-box-top">
      <!-- 左边数据 -->
      <div class="left-box">
        <!-- 数量 -->
        <div class="data-item data-count">
          <div class="data-num">
            <span>公厕总数</span>
            <span class="num">{{ washroomCount.toiletCount }}</span>
          </div>
          <div class="data-num">
            <span>转运站数量</span>
            <span class="num">{{ washroomCount.transferPointCount }}</span>
          </div>
        </div>
        <!-- 上线数 -->
        <div class="data-item data-online mtop">
          <div class="data-num">
            <span class="online-title">公厕上线数</span>
            <el-progress type="circle" :width="60" color="#2DE7C6" :percentage="toiletOnlinePercent"></el-progress>
          </div>
          <div class="data-num">
            <span class="online-title">转运站上线数</span>
            <el-progress type="circle" :width="60" color="#2DE7C6" :percentage="transferPointPercent"></el-progress>
          </div>
        </div>
        <!-- 排名 -->
        <div class="data-item mtop overflow">
          <div class="pmtitle">管养项目部排名</div>
          <el-table
            :data="tableData"
            height="230"
            size="small"
            stripe
            :header-cell-style="tableHeaderColor"
            :cell-style="rowClass"
          >
            <el-table-column
              align="center"
              min-width="100"
              prop="projectDeptName"
              label="项目部"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column align="center" prop="score" label="得分"></el-table-column>
            <el-table-column align="center" prop="scoreType" label="评分">
              <template slot-scope="scope">
                <span v-if="scope.row.scoreType == '优秀'" style="color:#00da24;font-weight:700 ;">{{
                  scope.row.scoreType
                }}</span>
                <span v-if="scope.row.scoreType == '良好'" style="color:#333333;font-weight:700 ;">{{
                  scope.row.scoreType
                }}</span>
                <span v-if="scope.row.scoreType == '不合格'" style="color:#ff5955;font-weight:700 ;">{{
                  scope.row.scoreType
                }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <!-- 地图 -->
      <div class="map-box">
        <Map :fang="fang" ref="boxMap" :location="true"></Map>
      </div>
      <!-- 右边数据 -->
      <div class="right-box">
        <div class="item-box alarm-top">
          <div class="data-item alarm-list">
            <div class="info-head">
              <span>公厕实时报警信息</span>
              <div>
                <span>(</span>
                <span style="color: red;">{{ washroomAlarmList.length }}</span>
                <span>/{{ washroomUnAlarmList.length + washroomAlarmList.length }})</span>
              </div>
            </div>
            <div class="auto alarmbox">
              <p v-if="washroomUnAlarmList.length + washroomAlarmList.length == 0" class="zanwu">暂无数据</p>
              <div v-else>
                <!-- 公厕报警列表，大于5条时滚动 -->
                <template v-if="washroomAlarmList.length > 5">
                  <vueSeamlessScroll :data="washroomAlarmList" :class-option="scrollOption" class="hidden">
                    <div class="info-item" v-for="item in washroomAlarmList" :key="item.id">
                      <div>
                        <span>{{ item.siteName }}</span>
                        <span v-if="item.type === 1">（男厕氨气报警）</span>
                        <span v-if="item.type === 2">（女厕氨气报警）</span>
                        <span v-if="item.type === 3">（男厕硫化氢报警）</span>
                        <span v-if="item.type === 4">（女厕硫化氢报警）</span> 
                        <span v-if="item.type === 5">（转运站报警）</span>
                      </div>
                      <el-button
                        type="danger"
                        size="mini"
                        plain
                        @click="goAlarmcord(item)"
                        >立即处置</el-button
                      >
                    </div>
                  </vueSeamlessScroll>
                </template>
                <template v-else>
                  <div class="info-item" v-for="item in washroomAlarmList" :key="item.id">
                    <div>
                      <span>{{ item.siteName }}</span>
                      <span v-if="item.type === 1">（男厕氨气报警）</span>
                      <span v-if="item.type === 2">（女厕氨气报警）</span>
                      <span v-if="item.type === 3">（男厕硫化氢报警）</span>
                      <span v-if="item.type === 4">（女厕硫化氢报警）</span>
                      <span v-if="item.type === 5">（转运站报警）</span>
                    </div>
                    <el-button
                      type="danger"
                      size="mini"
                      plain
                      @click="goAlarmcord(item)"
                      >立即处置</el-button
                    >
                  </div>
                </template>
                <!-- 公厕已处理列表，报警列表少于5条时显示 -->
                <template v-if="washroomAlarmList.length < 5">
                  <!-- 已处理列表大于剩下空间时滚动 -->
                  <template v-if="washroomUnAlarmList.length > 5 - washroomAlarmList.length">
                    <vueSeamlessScroll :data="washroomUnAlarmList" :class-option="scrollOption" class="auto hidden">
                      <div class="info-item" v-for="item in washroomUnAlarmList" :key="item.id">
                        <div>
                          <span>{{ item.toiletName }}</span>
                          <span v-if="item.type === 1">（男厕氨气报警）</span>
                          <span v-if="item.type === 2">（女厕氨气报警）</span>
                          <span v-if="item.type === 3">（男厕硫化氢报警）</span>
                          <span v-if="item.type === 4">（女厕硫化氢报警）</span>
                          <span v-if="item.type === 5">（转运站报警）</span>
                        </div>
                        <el-button
                          type="primary"
                          size="mini"
                          plain
                          @click="goAlarmcord(item)"
                          >查看详情</el-button
                        >
                      </div>
                    </vueSeamlessScroll>
                  </template>
                  <template v-else>
                    <div class="info-item" v-for="item in washroomUnAlarmList" :key="item.id">
                      <div>
                        <span>{{ item.toiletName }}</span>
                        <span v-if="item.type === 1">（男厕氨气报警）</span>
                        <span v-if="item.type === 2">（女厕氨气报警）</span>
                        <span v-if="item.type === 3">（男厕硫化氢报警）</span>
                        <span v-if="item.type === 4">（女厕硫化氢报警）</span>
                        <span v-if="item.type === 5">（转运站报警）</span>
                      </div>
                      <el-button
                        type="primary"
                        size="mini"
                        plain
                        @click="goAlarmcord(item)"
                        >查看详情</el-button
                      >
                    </div>
                  </template>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="item-box alarm-bottom">
          <div class="data-item alarm-list">
            <div class="info-head">
              <span>转运站实时报警信息</span>
              <div>
                <span>(</span>
                <span style="color: red;">{{ stationAlarmList.length }}</span>
                <span>/{{ stationUnAlarmList.length + stationAlarmList.length }})</span>
              </div>
            </div>
            <div class="auto alarmbox">
              <!-- 转运站报警列表，大于5条时滚动 -->
              <p v-if="stationUnAlarmList.length + stationAlarmList.length == 0" class="zanwu">暂无数据</p>
              <div v-else>
                <template v-if="stationAlarmList.length > 5">
                  <vueSeamlessScroll :data="stationAlarmList" :class-option="scrollOption" class="hidden">
                    <div class="info-item" v-for="item in stationAlarmList" :key="item.id">
                      <div>
                        <span>{{ item.siteName }}</span>
                        <span v-if="item.type === 1">（男厕氨气报警）</span>
                        <span v-if="item.type === 2">（女厕氨气报警）</span>
                        <span v-if="item.type === 3">（男厕硫化氢报警）</span>
                        <span v-if="item.type === 4">（女厕硫化氢报警）</span>
                        <span v-if="item.type === 5">（满载）</span>
                      </div>
                      <el-button
                        type="danger"
                        size="mini"
                        plain
                        @click="goAlarmcord(item)"
                        >立即处置</el-button
                      >
                    </div>
                  </vueSeamlessScroll>
                </template>
                <template v-else>
                  <div class="info-item" v-for="item in stationAlarmList" :key="item.id">
                    <div>
                      <span>{{ item.siteName }}</span>
                      <span v-if="item.type === 1">（男厕氨气报警）</span>
                      <span v-if="item.type === 2">（女厕氨气报警）</span>
                      <span v-if="item.type === 3">（男厕硫化氢报警）</span>
                      <span v-if="item.type === 4">（女厕硫化氢报警）</span>
                      <span v-if="item.type === 5">（满载）</span>
                    </div>
                    <el-button
                      type="danger"
                      size="mini"
                      plain
                      @click="goAlarmcord(item)"
                      >立即处置</el-button
                    >
                  </div>
                </template>
                <!-- 转运站已处理列表，报警列表少于5条时显示 -->
                <template v-if="stationAlarmList.length < 5">
                  <!-- 已处理列表大于剩下空间时滚动 -->
                  <template v-if="stationUnAlarmList.length > 5 - stationAlarmList.length">
                    <vueSeamlessScroll :data="stationUnAlarmList" :class-option="scrollOption" class="auto hidden">
                      <div class="info-item" v-for="item in stationUnAlarmList" :key="item.id">
                        <div>
                          <span>{{ item.siteName }}</span>
                          <span v-if="item.type === 1">（男厕氨气报警）</span>
                          <span v-if="item.type === 2">（女厕氨气报警）</span>
                          <span v-if="item.type === 3">（男厕硫化氢报警）</span>
                          <span v-if="item.type === 4">（女厕硫化氢报警）</span>
                          <span v-if="item.type === 5">（满载）</span>
                        </div>
                        <el-button
                          type="primary"
                          size="mini"
                          plain
                          @click="goAlarmcord(item)"
                          >查看详情</el-button
                        >
                      </div>
                    </vueSeamlessScroll>
                  </template>
                  <template v-else>
                    <div class="info-item" v-for="item in stationUnAlarmList" :key="item.id">
                      <div>
                        <span>{{ item.siteName }}</span>
                        <span v-if="item.type === 1">（男厕氨气报警）</span>
                        <span v-if="item.type === 2">（女厕氨气报警）</span>
                        <span v-if="item.type === 3">（男厕硫化氢报警）</span>
                        <span v-if="item.type === 4">（女厕硫化氢报警）</span>
                        <span v-if="item.type === 5">（满载）</span>
                      </div>
                      <el-button
                        type="primary"
                        size="mini"
                        plain
                       @click="goAlarmcord(item)"
                        >查看详情</el-button
                      >
                    </div>
                  </template>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 排列图表 -->
    <div class="data-box-bottom">
      <div class="data-item data-chart">
        <div class="chart-box">
          <span>公厕{{ healthState1 }}度氨气报警次数</span>
          <el-radio-group class="custom-radio-group-box" v-model="healthState1" size="mini" @change="chartChange($event, 1)">
            <el-radio-button label="月"></el-radio-button>
            <el-radio-button label="年"></el-radio-button>
          </el-radio-group>
        </div>
        <div class="auto c-center">
        
          <Echart v-if="chartDataShow1" :option="chartData1" :navtor="true" :date="dateyear" :type="1"></Echart>
          <div class="echartShow" v-else></div>
          
        </div>
      </div>
      <div class="data-item data-chart">
        <div class="chart-box">
          <span>公厕{{ healthState2 }}度硫化氢报警次数</span>
          <el-radio-group class="custom-radio-group-box" v-model="healthState2" size="mini" @change="chartChange($event, 2)">
            <el-radio-button label="月"></el-radio-button>
            <el-radio-button label="年"></el-radio-button>
          </el-radio-group>
        </div>
        <div class="auto c-center">
          <Echart v-if="chartDataShow2" :option="chartData2" :navtor="true" :date="dateyear" :type="2"></Echart>
          <div class="echartShow" v-else></div>
        </div>
      </div>
      <div class="data-item data-chart">
        <div class="chart-box">
          <span>转运站{{ healthState3 }}度报警次数</span>
          <el-radio-group class="custom-radio-group-box" v-model="healthState3" size="mini" @change="chartChange($event, 3)">
            <el-radio-button label="月"></el-radio-button>
            <el-radio-button label="年"></el-radio-button>
          </el-radio-group>
        </div>
        <div class="auto c-center">
          <Echart v-if="chartDataShow3" :option="chartData3" :navtor="true" :date="dateyear" :type="3"></Echart>
          <div class="echartShow" v-else></div>
        </div>
      </div>
      <div class="data-item data-chart">
        <div class="chart-box">
          <span>转运站{{ healthState4 }}度垃圾清运次数</span>
          <el-radio-group class="custom-radio-group-box" v-model="healthState4" size="mini" @change="chartChange2">
            <el-radio-button label="月"></el-radio-button>
            <el-radio-button label="年"></el-radio-button>
          </el-radio-group>
        </div>
        <div class="auto c-center">
          <Echart v-if="chartDataShow4" :option="chartData4" :navtor="true" :date="dateyear"></Echart>
          <div class="echartShow" v-else></div>
        </div>
      </div>
    </div>
    <NavBottom :ctrl="false"></NavBottom>
  </div>
</template>

<script>
import Echart from "./Echart";
import Map from "./map/Map";
import axios from "../request";
import NavBottom from "./NavBottom";
import * as echarts from "echarts";
import vueSeamlessScroll from "vue-seamless-scroll";
export default {
  name: "Home",
  components: { Echart,  NavBottom, Map,vueSeamlessScroll },
  data() {
    return {
      
      scrollOption: {
        limitMoveNum: 1,
      },
      loading: true,
      fang: true,
      healthState1: "月",
      healthState2: "月",
      healthState3: "月",
      healthState4: "月",
      onlineNum1: 62,
      onlineNum2: 85,
      tableData: [],
      washroomCount: {
        toiletCount: 0,
        toiletOnlineCount: 0,
        toiletOfflineCount: 0,
        transferPointCount: 0,
        transferPointOnlineCount: 0,
        transferPointOfflineCount: 0,
      },
      toiletOnlinePercent: 0,
      transferPointPercent: 0,
      washroomAlarmList: [],
      washroomUnAlarmList: [],
      stationAlarmList: [],
      stationUnAlarmList: [],
      month: 1,
      year: 2021,
      dateyear: true,
      // 公厕氨气
      chartData1: null,
      // 公厕硫化氢
      chartData2: null,
      // 转运站报警
      chartData3: null,
      // 转运站清理
      chartData4: null,
      chartDataShow1: false, //公厕氨气图表数据
      chartDataShow2: false, //公厕硫化氢图表数据
      chartDataShow3: false, //转运站报警图表数据
      chartDataShow4: false, //转运站清运图表数据
    };
  },
  watch: {
    washroomCount(val) {
      // console.log(val);
      this.toiletOnlinePercent = val.toiletOnlineCount == 0 ? 0 : Math.floor((val.toiletOnlineCount / val.toiletCount) * 100);
      this.transferPointPercent =
        val.transferPointOnlineCount == 0 ? 0 : Math.floor((val.transferPointOnlineCount / val.transferPointCount) * 100);
    },
  },
  methods: {
    chartData(type) {
      const style = type === 1 || type === 2;
      return {
        // tooltip: {
        //     axis: true
        // },
        // grid: {
        //     left: 15,
        //     right: 15,
        //     bottom: 40,
        //     top: 40,
        // },
        // xAxis: {
        //     type: "category",
        //     data: [],
        //     axisTick: {
        //         show: false,

        //     },
        //     axisLine:{
        //         lineStyle:{
        //             color:'#e0ebff',
        //         },
        //     },
        //     axisLabel: {
        //         rotate: 45,
        //         interval: 0,
        //         align: "center",
        //         verticalAlign: "btoom",
        //         margin: 20,
        //         show: true,
        //          textStyle: {
        //             color: "#333333",
        //         }
        //     },
        // },
        // yAxis: {
        //     show: false,
        // },
        // series: [
        //     {
        //         data: [],
        //         type: "bar",
        //         showBackground: true,
        //         barWidth: 12,
        //         backgroundStyle: {
        //             color: "#E0EBFF",
        //             borderRadius: [20, 20, 0, 0],
        //         },
        //         itemStyle: {
        //             color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        //                 { offset: 0, color: style ? "#FF6611" : "#1467FF" },
        //                 { offset: 1, color: style ? "#FFAF83" : "#BBD2FC" },
        //             ]),
        //             borderRadius: [20, 20, 0, 0],
        //         },
        //         label: {
        //             show: true,
        //             position: "top",
        //             color: style ? "#FF610B" : "#1467FF",
        //             fontSize: 15,
        //             fontWeight: "bold",
        //         },
        //     },
        // ],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        grid: {
          left: 15,
          right: 15,
          bottom: 40,
          top: 40,
        },
        xAxis: {
          type: "category",
          data: [],
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: "#e0ebff",
            },
          },
          axisLabel: {
            rotate: 0,
            interval: 0,
            align: "center",
            verticalAlign: "btoom",
            margin: 20,
            show: true,
            textStyle: {
              color: "#333333",
            },
          },
          z: 10,
        },
        yAxis: {
          show: false,
        },
        dataZoom: [
          {
            show: true,
            realtime: true,
            start: 0,
            end: 10,
          },
          {
            type: "inside",
            realtime: true,
            start: 0,
            end: 15,
          },
        ],
        series: [
          {
            data: [],
            type: "bar",
            showBackground: true,
            barWidth: 12,
            backgroundStyle: {
              color: "#E0EBFF",
              borderRadius: [20, 20, 0, 0],
            },
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: style ? "#FF6611" : "#1467FF" },
                { offset: 1, color: style ? "#FFAF83" : "#BBD2FC" },
              ]),
              borderRadius: [20, 20, 0, 0],
            },
            label: {
              show: true,
              position: "top",
              color: style ? "#FF610B" : "#1467FF",
              fontSize: 15,
              fontWeight: "bold",
            },
          },
        ],
      };
    },
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return "font-size:16px;font-weight: 500;background:#e9ecf7;border-radius: 3px;";
      }
    },
    rowClass() {},
    // 获取项目部排名数据
    async getProjectList() {
      let data = await axios.get("/data/index/toiletAssess");
      if (data) {
        this.tableData = data;
      }
    },
    // 获取公厕数量
    async getItemCount() {
      let data = await axios.get("/data/index/findSiteInfo");
      if (data) {
        this.washroomCount = data;
      }
    },
    // 获取公厕实时报警信息
    async getWashRoomAlarm() {
      let data = await axios.get("/data/index/alarmRecord/findList", { params: { siteType: 1 } });
      if (data) {
        this.washroomAlarmList = data.noDisposeLists;
        // console.log(this.washroomAlarmList)
        this.washroomUnAlarmList = data.disposeLists;
      }
    },
    // 跳转报警记录页面
    goAlarmcord(item){
      let name =  localStorage.getItem("name")?? sessionStorage.getItem("name");
      console.log(item)
      if (name !== '普通用户'){
        this.$router.push({ name: 'alarmrecord', params: { userId: item.id, status: item.siteType, typeID: item.status } })
      }

    },
    // 获取转运站实时报警信息
    async getStationAlarm() {
      let data = await axios.get("/data/index/alarmRecord/findList", { params: { siteType: 2 } });
      if (data) {
       
        this.stationAlarmList = data.noDisposeLists;
        console.log(this.stationAlarmList)
        this.stationUnAlarmList = data.disposeLists;
        // console.log(this.stationUnAlarmList)
      }
    },
    // 获取图表数据
    async getChartData(type, year, month = null) {
      const query = { alarmType: type, year, month };
      let data = await axios.get("/data/index/findAlarmStatistics", { params: query });
      
      console.log(data)
      if (data) {
        const chartData = this.chartData(type);

        const num = [],
          name = [];
        data.forEach((item) => {
          num.push(item.alarmCount);
          name.push(item.siteName);
        });
        

        chartData.xAxis.data = name;
        chartData.series[0].data = num;
        this["chartData" + type] = chartData;
        this["chartDataShow" + type] = num.length > 0 ? true : false;
      }
    },
    // 获取转运站图表数据
    async getStationChartData(year, month = null) {
      const query = { year, month };
      let data = await axios.get("/data/index/transferPointAssess", { params: query });
      if (data) {
        const chartData = this.chartData();
        const num = [],
          name = [];
        data.forEach((item) => {
          num.push(item.alarmCount);
          name.push(item.siteName);
        });
        chartData.xAxis.data = name;
        chartData.series[0].data = num;
        this.chartData4 = chartData;
        this.chartDataShow4 = num.length > 0 ? true : false;
      }
    },

    chartChange(e, type) {
      if (e === "月") {
        this.getChartData(type, this.year, this.month);
        this.dateyear = true
      } else {
        this.getChartData(type, this.year);
        this.dateyear = false
      }
    },
    chartChange2(e) {
      if (e === "月") {
        this.getStationChartData(this.year, this.month);
      } else {
        this.getStationChartData(this.year);
      }
    },
    setDateMonth() {
      let myDate = new Date();
      let dataIng = myDate.getFullYear();
      myDate.setMonth(myDate.getMonth() );
      let dataed = myDate.getMonth() + 1;
    },
    clickEchart(val) {
      console.log(val);
    },
  },
  async created() {
    let time = new Date();
    // console.log(time)
    this.year = time.getFullYear();
    time.setMonth(time.getMonth() );
    this.month = time.getMonth() ;
    // console.log(this.month)
    this.getItemCount();
    this.getProjectList();
    this.getWashRoomAlarm();
    this.getStationAlarm();
    this.getChartData(1, this.year, this.month);
    this.getChartData(2, this.year, this.month);
    this.getChartData(3, this.year, this.month);
    this.getStationChartData(this.year, this.month);
    this.$nextTick(function() {
      console.log(this.$refs.boxMap);
      this.$refs.boxMap.$el.children[0].style = "right:18%";
      this.$refs.boxMap.$el.children[2].style.left = "15%";
      // this.$refs.boxMap.$el.children[4].style.position = "absolute"
      console.log(this.$refs.boxMap.$el.children[4].style)
      let name = document.getElementById("data1").innerHTML;
      console.log(name);
    });
  },
};
</script>

<style scoped lang="scss">
.el-table {
  color: #333333;
}
.home {
  height: calc(100% - 30px);
  width: calc(100% - 30px);
  box-sizing: border-box;
  margin: 15px;
  position: relative;
}
.data-box-top {
  display: flex;
  height: 60%;
}
.data-box-bottom {
  display: flex;
  height: 40%;
  padding-top: 20px;
  box-sizing: border-box;
}
.data-item {
  border-top: solid 3px $blue;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding: 10px 10px 0 10px;
  flex: auto;
  background: white;
  box-sizing: border-box;
}
.data-count {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.data-num {
  color: #778ca2;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.chart-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $blue;
  font-weight: bold;
  font-size: 1.2rem;
  padding: 0 20px;
  > div {
    flex: none;
  }
}
.num {
  color: $blue;
  font-size: 1.5rem;
  margin-top: 10px;
}
.data-online {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.online-title {
  margin-bottom: 10px;
}
#map {
  height: 100%;
}
.map-box {
  flex: auto;
  margin: 0 15px;
  border-radius: 4px;
}
.alarm-bottom,
.alarm-top {
  height: calc(50% - 10px);
  box-sizing: border-box;
}
.alarm-list {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}
.pmtitle {
  text-align: center;
  color: $blue;
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 5px;
}
.data-chart {
  width: calc((100% - 45px) / 4);
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  padding: 20px 0;
}
.data-chart:first-child {
  margin-left: 0;
}
.info-head {
  display: flex;
  justify-content: space-between;
  font-size: 1.2rem;
  color: $blue;
  font-weight: bold;
  margin-bottom: 10px;
}
.info-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  border-top: solid 1px #eee;
  white-space: nowrap;
  font-size: 14px;
}
.info-btn {
  margin-left: 20px;
}
.left-box,
.right-box {
  flex: auto;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  .alarmbox {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
}
.item-box {
  display: flex;
  overflow: auto;
  align-items: flex-start;
}
.overflow {
  overflow: auto;
}
.hidden {
  overflow: hidden;
}
.mtop {
  margin-top: 15px;
}
.echartShow {
  height: 100%;
  width: 100%;
  background: url("../assets/img/zanwu.png") center/contain no-repeat, white;
  background-size: 40%;
}
.cell {
  font-size: 16px !important;
}
.el-table--border::after,
.el-table--group::after,
.el-table::before {
  background-color: white;
}
.zanwu {
  color: grey;
  font-size: 14px;
  text-align: center;
  line-height: 150px;
}
</style>
